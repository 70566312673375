var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":_vm.modal_id,"title":`Seleccionar ${_vm.$getVisibleNames(
      'mesh.competence',
      true,
      'Competencias'
    )}`,"size":"lg"},on:{"hide":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"btn-cancel",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.modal_id)}}},[_vm._v("Cancelar")]),_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm"},on:{"click":_vm.accept}},[_vm._v("Guardar")])]},proxy:true}])},[_c('CompetenceTable',{attrs:{"allows_crud":false,"selection_mode":true,"matrix2_competences":_vm.matrix2_competences,"selected_ids":_vm.selected_competence_ids,"egress_profile_id":_vm.egress_profile_id},on:{"competence_changed":_vm.slotCompetenceChanged}})],1),_c('b-modal',{attrs:{"id":_vm.modal_id + '-capacity-' + _vm.matter_id,"title":`Seleccionar ${_vm.$getVisibleNames(
      'mesh.capacity',
      true,
      'Capacidades'
    )}`,"size":"lg","hide-footer":""}},[(_vm.matter)?_c('CapacityTable',{attrs:{"matter":_vm.matter,"competence_selected_ids":_vm.selected_competence_ids,"matrix2_competences":_vm.matrix2_competences},on:{"close_modal":function($event){return _vm.$bvModal.hide(_vm.modal_id + '-capacity-' + _vm.matter_id)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }